// components/BeforeAfterSlider.tsx
"use client";
import { useState, useRef, useEffect } from "react";
import { Box } from "@mantine/core";
import Image from "next/image";

interface BeforeAfterSliderProps {
  beforeImage: string;
  afterImage: string;
  alt: string;
  height: string;
}

const BeforeAfterSlider = ({ beforeImage, afterImage, alt, height }: BeforeAfterSliderProps) => {
  const [sliderPosition, setSliderPosition] = useState(50);
  const containerRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);

  const handleMove = (clientX: number) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const offsetX = clientX - rect.left;
      const newPosition = Math.max(0, Math.min(100, (offsetX / rect.width) * 100));
      setSliderPosition(newPosition);
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent default selection behavior
    const handleMouseMove = (e: MouseEvent) => handleMove(e.clientX);
    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    e.preventDefault(); // Prevent default selection behavior
    const handleTouchMove = (e: TouchEvent) => handleMove(e.touches[0].clientX);
    const handleTouchEnd = () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };

    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.addEventListener("mousedown", handleMouseDown as any);
      slider.addEventListener("touchstart", handleTouchStart as any);

      return () => {
        slider.removeEventListener("mousedown", handleMouseDown as any);
        slider.removeEventListener("touchstart", handleTouchStart as any);
      };
    }
  }, []);

  return (
    <Box
      ref={containerRef}
      pos="relative"
      w="100%"
      h="100%"
      mih={height}
      style={{
        overflow: "hidden",
        userSelect: "none",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
      }}
    >
      <Image src={beforeImage} alt={`${alt} (before)`} layout="fill" objectFit="cover" draggable={false} />
      <Box
        pos="absolute"
        top={0}
        left={0}
        w="100%"
        h="100%"
        style={{
          clipPath: `inset(0 ${100 - sliderPosition}% 0 0)`,
        }}
      >
        <Image src={afterImage} alt={`${alt} (after)`} layout="fill" objectFit="cover" draggable={false} />
      </Box>
      <Box
        ref={sliderRef}
        pos="absolute"
        top={0}
        left={`${sliderPosition}%`}
        h="100%"
        w="4px"
        bg="lime.1"
        style={{
          transform: "translateX(-50%)",
          cursor: "ew-resize",
        }}
      >
        <Box
          pos="absolute"
          top="50%"
          left="50%"
          w={30}
          h={30}
          bg="white"
          style={{
            transform: "translate(-50%, -50%)",
            borderRadius: "50%",
            boxShadow: "0 0 0 4px var(--mantine-color-lime-1)",
          }}
        />
      </Box>
    </Box>
  );
};

export default BeforeAfterSlider;
