"use client";
import { Carousel } from "@mantine/carousel";
import { Button, Flex, Grid, GridCol, Group, Space, Text, Title, useMantineColorScheme } from "@mantine/core";
import Autoplay from "embla-carousel-autoplay";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useRef, useState } from "react";
import classes from "./HeroSection.module.css";
import BeforeAfterSlider from "../BeforeAfterSlider";

const sliders = [
  { firstImage: "/converter_1_original.png", secondImage: "/converter_1.png" },
  { firstImage: "/converter_2_original.png", secondImage: "/converter_2.png" },
  { firstImage: "/converter_3_original.png", secondImage: "/converter_3.png" },
];

export default function HeroSection() {
  const router = useRouter();
  const autoplay = useRef(Autoplay({ delay: 2000 }));
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % sliders.length);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + sliders.length) % sliders.length);
  };

  return (
    <>
      <Grid py="xl" pos="relative">
        <GridCol span={{ base: 12, sm: 6 }}>
          <Flex justify="center" direction="column" flex={1} h="100%" className={classes.inner}>
            <Title className={classes.title}>Create printer-friendly coloring pages in a snap!</Title>
            <Text mt="md" className={classes.content}>
              Make AI generated coloring sheets, limited only by your imagination 😎
            </Text>

            <Group mt={30}>
              <Link
                href={`/ai-coloring-page-generator`}
                style={{ textDecoration: "none", margin: "auto", width: "100%" }}
              >
                <Button size="md" className={classes.control} fullWidth color="wumpus" name="make ai coloring page">
                  Make an AI Generated Coloring Page
                </Button>
              </Link>
              <Link
                href={`/coloring-pages`}
                style={{
                  margin: "auto",
                  width: "100%",
                }}
              >
                <Text ta="center" c="dimmed">
                  or, view existing coloring pages
                </Text>
              </Link>
            </Group>
          </Flex>
        </GridCol>
        <GridCol span={{ base: 12, sm: 6 }} mt={{ base: 0, md: "lg" }}>
          <Carousel
            className={classes.carouselContainer}
            withIndicators
            loop
            slideGap="xs"
            controlsOffset="xl"
            controlSize={40}
            align="start"
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
          >
            <Carousel.Slide>
              <div className={classes.carouselImage}>
                <Image
                  style={{ borderRadius: ".25rem" }}
                  src={"/carousel_1.png"}
                  decoding="async"
                  alt="coloring page"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </Carousel.Slide>
            <Carousel.Slide>
              <div className={classes.carouselImage}>
                <Image
                  style={{ borderRadius: ".25rem" }}
                  src={"/carousel_2.png"}
                  alt="kid coloring page"
                  decoding="async"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </Carousel.Slide>
            <Carousel.Slide>
              <div className={classes.carouselImage}>
                <Image
                  style={{ borderRadius: ".25rem" }}
                  src={"/carousel_3.png"}
                  decoding="async"
                  alt="kid coloring page"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </Carousel.Slide>
            <Carousel.Slide>
              <div className={classes.carouselImage}>
                <Image
                  style={{ borderRadius: ".25rem" }}
                  src={"/carousel_4.png"}
                  decoding="async"
                  alt="kid coloring page"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </Carousel.Slide>
            <Carousel.Slide>
              <div className={classes.carouselImage}>
                <Image
                  style={{ borderRadius: ".25rem" }}
                  src={"/carousel_5.png"}
                  alt="kid coloring page"
                  decoding="async"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </Carousel.Slide>
          </Carousel>
        </GridCol>

        <Space h="xl" w="100%" />

        <GridCol span={{ base: 12, sm: 6 }} mt={{ base: 0, md: "lg" }} pos="relative">
          <Flex
            justify="space-between"
            align="stretch"
            p="xl"
            className={classes.beforeAfterContainer}
            direction="column"
            style={{ borderRadius: "4rem", position: "relative" }}
          >
            <div className={classes.imageContainer} style={{ borderRadius: "4rem", flex: 1, position: "relative" }}>
              <BeforeAfterSlider
                beforeImage={sliders[activeIndex].firstImage}
                height="550px"
                alt={"converting a photo to a coloring page"}
                afterImage={sliders[activeIndex].secondImage}
              />
              <Flex w="100%" justify="space-between" px="4rem" mt="md" style={{ position: "absolute", bottom: 0 }}>
                <Button onClick={handlePrev} name="previous">
                  &lt; Prev
                </Button>
                <Button onClick={handleNext} name="next">
                  Next &gt;
                </Button>
              </Flex>
            </div>
          </Flex>
        </GridCol>
        <GridCol span={{ base: 12, sm: 6 }}>
          <Flex justify="center" direction="column" flex={1} h="100%" className={classes.inner}>
            <Title className={classes.title} order={2}>
              Convert Your Photos to Coloring Pages
            </Title>
            <Text mt="md" className={classes.content}>
              Bring your memories to life in a new way! Convert your photos into outline drawings with our easy-to-use
              coloring page converter. Whether it's a family portrait or a favorite pet, our tool converts your photos
              into beautiful, printer-friendly coloring pages.
            </Text>

            <Group mt={30}>
              <Link
                href={`/photo-to-coloring-page-converter`}
                style={{ textDecoration: "none", margin: "auto", width: "100%" }}
              >
                <Button size="md" className={classes.control} fullWidth color="lime" name="convert coloring page">
                  Convert Image to Coloring Page Free
                </Button>
              </Link>
              <Link
                href={`/coloring-pages`}
                style={{
                  margin: "auto",
                  width: "100%",
                }}
              >
                <Text ta="center" c="dimmed">
                  or, view existing coloring pages
                </Text>
              </Link>
            </Group>
          </Flex>
        </GridCol>
        <Space h="xl" w="100%" />
        <Space h="xl" w="100%" />
        <Space h="xl" w="100%" />
        <Grid.Col
          span={12}
          ta="center"
          p="lg"
          style={{
            backgroundColor: "light-dark(var(--mantine-color-wumpus-1), var(--mantine-color-dark-6))",
            borderRadius: ".25rem",
          }}
        >
          <Text fz="h2">Ready for some relaxing fun?</Text>
          <Link href={`/coloring-pages`} style={{ textDecoration: "none", margin: "auto" }}>
            <Button
              size="md"
              className={classes.control}
              onClick={() => router.push("/login")}
              mt="md"
              name="browse coloring pages"
            >
              Browse Free Coloring Pages
            </Button>
          </Link>
        </Grid.Col>
      </Grid>
    </>
  );
}
